export interface ProductGrade {
  grade: string
  image: any
  image2: any
  image2Big: any
  image3: any
  title: string
  explain: string
  explain2: string // 제품상세에서 보여지는 설명
  subexplain?: string
  color: string
  order: number
}

const commomProductGrades: ProductGrade[] = [
  {
    grade: 'S',
    image: '/images/main/grade/S_grade.png', //카테고리 상품에 표시해줄 아이콘
    image2: '/images/main/grade/S_grade.png',
    image2Big: '/images/main/grade/S_grade_big.png',
    image3: '/images/main/grade/S_grade_DetailPage.png', //카테고리 상품에 표시해줄 아이콘
    title: '강추해요',
    explain: '추천 제품 중에서 알레르기 혹은 피부과민 반응을 유발할 수 있는 성분도 타제품에 비해 적거나 없는 제품',
    explain2: 'A등급 제품 중에서 알레르기를 유발할 수 있는 성분도 타 제품에 비해 적거나 없는 제품',
    color: '#00A688',
    order: 1,
  },

  {
    grade: 'V2',
    image: '/images/main/grade/B_grade_Category.png', //카테고리 상품에 표시해줄 아이콘
    image2: '/images/main/grade/V2_grade.png',
    image2Big: '/images/main/grade/V2_grade.png',
    image3: '/images/main/grade/V2_grade_DetailPage.png', //카테고리 상품에 표시해줄 아이콘
    title: '무난해요',
    explain:
      '제품 등급 B~D등급의 제품 중 우려 성분에 대한 소명자료(유해물질 불검출 성적서 및 각종 인증서)를 제출한 경우, 맘가이드 측에서 직접 검토하여 무난하게 사용할 수 있는 제품',
    explain2: 'B~C등급의 제품 중 소명자료(주의 성분 불검출 성적서 등)를 직접 검토하여 무난하게 사용할 수 있는 제품',
    color: '#75BF42',
    order: 4,
  },
  {
    grade: 'B',
    image: '/images/main/grade/B_grade.png', //카테고리 상품에 표시해줄 아이콘
    image2: '/images/main/grade/B_grade.png',
    image2Big: '/images/main/grade/B_grade_big.png',
    image3: '/images/main/grade/B_grade_DetailPage.png', //카테고리 상품에 표시해줄 아이콘
    title: '무난해요',
    explain: '일반적으로 사용하기에 큰 문제를 일으킬만한 성분은 없거나, 그 영향이 적은 제품',
    explain2: '일반적으로 사용하기에 큰 문제를 일으킬 만한 성분은 없거나, 그 영향이 적은 제품',
    color: '#75BF42',
    order: 3,
  },
  {
    grade: 'C',
    image: '/images/main/grade/C_grade.png', //카테고리 상품에 표시해줄 아이콘
    image2: '/images/main/grade/C_grade.png',
    image2Big: '/images/main/grade/C_grade_big.png',
    image3: '/images/main/grade/C_grade_DetailPage.png', //카테고리 상품에 표시해줄 아이콘
    title: '아쉬워요',
    explain: '몇가지 함유성분이 유해성 논란이 있기에 아쉬운 제품',
    explain2: '몇가지 함유성분이 유해성 논란이 있기에 아쉬운 제품',
    color: '#FFBF1F',
    order: 4,
  },
  {
    grade: 'D',
    image: '/images/main/grade/D_grade.png', //카테고리 상품에 표시해줄 아이콘
    image2: '/images/main/grade/D_grade.png',
    image2Big: '/images/main/grade/D_grade_big.png',
    image3: '/images/main/grade/D_grade_DetailPage.png', //카테고리 상품에 표시해줄 아이콘
    title: '비추에요',
    explain:
      '인체에 잠재적이지만 심각한 유해성이 있는 성분들이 함유된, 혹은 다수의 유해성 논란성분이 함유된 제품. 영유아용으로 사용은 자제하시길 바라며, 성인들이 사용시에도 안전수칙에 맞게! 적정량! 사용해야합니다.',
    explain2: '잠재적으로 심각한 유해성 논란 성분이 함유되거나 주의 성분이 다수 함유된 제품',
    color: '#FF8812',
    order: 5,
  },
  {
    grade: 'X',
    image: '/images/main/grade/X_grade.png',
    image2: '/images/main/grade/X_grade.png',
    image2Big: '/images/main/grade/X_grade_big.png',
    image3: '/images/main/grade/X_grade_DetailPage.png',
    title: '성분 미공개 & 이슈',
    explain:
      '-전성분 공개 의무가 없는 생활화학제품(세탁세제, 세정제 류) 중 제조/판매업체에서 제품 성분의 일부 혹은 전체를 공개하지 않은 경우\n\n-일부 물티슈 성분(제올라이트 등) 중 안전성과 보존력에 대해 충분한 검증이 필요해 보이는 경우\n\n-유해물질 검출 이력이 있는 경우("강추" 제품이라도 법적 허용치 이상의 유해물질 검출 이력이 있다면 이 등급이 부여됩니다.)',
    explain2: '전성분이 명확하게 공개되지 않았거나, 유해물질 검출이력이나 성분 관련 이슈가 있는 제품',
    color: '#B2B2B2',
    order: 6,
  },
]

export const momguideProductGrades: ProductGrade[] = [
  {
    grade: 'V',
    image: '/images/main/grade/V_grade2023.png',
    image2: '/images/main/grade/V_grade.png',
    image2Big: '/images/main/grade/V_grade_big.png',
    image3: '/images/main/grade/V_grade_DetailPage.png',
    title: '맘가이드 클린 제품',
    explain:
      '맘가이드 자체적으로 제조/판매업체로부터 각종 시험성적서, 안전성 평가자료를 직접 검수하여 성분 및 제품의 안전성을 확인한 제품\n\n성분 안전성 측면에서는 A등급 이상에 준하며, 각종 인증서 원본 및 허위/과장광고 여부 등을 확인하여 타제품에 비해 더 신뢰할 수 있는 제품',
    explain2: '성분 안전성 및 투명성에 대한 맘가이드의 엄격한 기준을 충족한 맘가이드 인증 등급',
    subexplain: '(다소 우려되는 성분이 있더라도 해당 성분 및 제품에 대한 안전성 평가자료가 있다면, 이 등급이 부여될 수 있습니다.)',
    color: '#0AD3BD',
    order: 0,
  },
  {
    grade: 'A',
    image: '/images/main/grade/A_grade.png', //카테고리 상품에 표시해줄 아이콘
    image2: '/images/main/grade/A_grade.png',
    image2Big: '/images/main/grade/A_grade_big.png',
    image3: '/images/main/grade/A_grade_DetailPage.png', //카테고리 상품에 표시해줄 아이콘
    title: '추천해요',
    explain: '맘가이드에서 엄격한 기준으로 선별한 안심 성분 제품',
    explain2: '영유아 · 임산부 주의 성분이 함유되지 않은 제품',
    subexplain:
      '(향을 중시하는 분들도 계시기에 추천제품 선정에 있어 착향 성분 사용여부는 고려하지 않았습니다. 즉, 추천제품에도 개개인 특성 따라 알레르기 및 과민반응을 일으킬 수 있는 천연 에센셜오일 및 착향 성분이 포함될 수 있습니다.\n모든 제품 사용 시 테스트를 권장드리며, 테스트 후 알레르기 및 과민반응이 없다면 안전합니다.)',
    color: '#0F9B50',
    order: 2,
  },
  ...commomProductGrades,
]

export const petguideProductGrades: ProductGrade[] = [
  {
    grade: 'V',
    image: '/images/main/grade/V_gradepet2023.png',
    image2: '/images/main/grade/V_gradepet.png',
    image2Big: '/images/main/grade/V_gradepet.png',
    image3: '/images/main/grade/V_gradepet_DetailPage.png',
    title: '펫가이드 클린 제품',
    explain:
      '펫가이드 자체적으로 제조/판매업체로부터 각종 시험성적서, 안전성 평가자료를 직접 검수하여 성분 및 제품의 안전성을 확인한 제품\n\n성분 안전성 측면에서는 A등급 이상에 준하며, 각종 인증서 원본 및 허위/과장광고 여부 등을 확인하여 타제품에 비해 더 신뢰할 수 있는 제품',
    explain2: '성분 안전성 및 투명성에 대한 펫가이드의 엄격한 기준을 충족한 펫가이드 인증 등급',
    subexplain: '(다소 우려되는 성분이 있더라도 해당 성분 및 제품에 대한 안전성 평가자료가 있다면, 이 등급이 부여될 수 있습니다.)',
    color: '#FFB000',
    order: 0,
  },
  {
    grade: 'A',
    image: '/images/main/grade/A_grade.png', //카테고리 상품에 표시해줄 아이콘
    image2: '/images/main/grade/A_grade.png',
    image2Big: '/images/main/grade/A_grade_big.png',
    image3: '/images/main/grade/A_grade_DetailPage.png', //카테고리 상품에 표시해줄 아이콘
    title: '추천해요',
    explain: '맘가이드에서 엄격한 기준으로 선별한 안심 성분 제품',
    explain2: '민감한 반려동물 주의 성분이 함유되지 않은 제품',
    subexplain:
      '(향을 중시하는 분들도 계시기에 추천제품 선정에 있어 착향 성분 사용여부는 고려하지 않았습니다. 즉, 추천제품에도 개개인 특성 따라 알레르기 및 과민반응을 일으킬 수 있는 천연 에센셜오일 및 착향 성분이 포함될 수 있습니다.\n모든 제품 사용 시 테스트를 권장드리며, 테스트 후 알레르기 및 과민반응이 없다면 안전합니다.)',
    color: '#0F9B50',
    order: 2,
  },
  ...commomProductGrades,
]

export const images = {
  cautionRed: '/images/main/ingredient/caution_red.png',
  cautionYellow: '/images/main/ingredient/caution_yellow.png',

  // ingredientGradeGood: '/images/main/ingredient/ingredientGradeGood.png',
  // ingredientGradeNormal: '/images/main/ingredient/ingredientGradeNormal.png',
  // ingredientGradeBad: '/images/main/ingredient/ingredientGradeBad.png',

  ewgGradeA: '/images/main/ingredient/a.png',
  ewgGradeB: '/images/main/ingredient/b.png',
  ewgGradeC: '/images/main/ingredient/c.png',
  ewgGradeD: '/images/main/ingredient/d.png',
  ewgGradeF: '/images/main/ingredient/f.png',
  ewgGradeX: '/images/main/ingredient/x.png',
  ewgCodeAA: '/images/main/ingredient/aa.png',
  ewgCodeAB: '/images/main/ingredient/ab.png',
  ewgCodeAC: '/images/main/ingredient/ac.png',
  ewgCodeBA: '/images/main/ingredient/ba.png',
  ewgCodeBB: '/images/main/ingredient/bb.png',
  ewgCodeBC: '/images/main/ingredient/bc.png',
  ewgCodeCA: '/images/main/ingredient/ca.png',
  ewgCodeCB: '/images/main/ingredient/cb.png',
  ewgCodeCC: '/images/main/ingredient/cc.png',
  ewgCodeX: '/images/main/ingredient/x2.png',
  // 호흡기 과민성
  echaBreathRed: '/images/main/ingredient/echaBreathRed.png',
  echaBreathGray: '/images/main/ingredient/echaBreathGray.png',
  // 피부 과민성
  echaSkinRed: '/images/main/ingredient/echaSkinRed.png',
  echaSkinGray: '/images/main/ingredient/echaSkinGray.png',

  // 발달 생식 독성
  echaGrowthRed: '/images/main/ingredient/echaGrowthRed.png',
  echaGrowthGray: '/images/main/ingredient/echaGrowthGray.png',
  isSlsSlesRed: '/images/main/ingredient/isSlsSlesRed.png',
  isSlsSlesGray: '/images/main/ingredient/isSlsSlesGray.png',
  isAmmoniumRed: '/images/main/ingredient/isAmmoniumRed.png',
  isAmmoniumYellow: '/images/main/ingredient/isAmmoniumYellow.png',
  isAmmoniumGray: '/images/main/ingredient/isAmmoniumGray.png',
  isFragranceRed: '/images/main/ingredient/isFragranceRed.png',
  isFragranceGray: '/images/main/ingredient/isFragranceGray.png',
  isColorRed: '/images/main/ingredient/isColorRed.png',
  isColorGray: '/images/main/ingredient/isColorGray.png',
  isHumidRed: '/images/main/ingredient/isHumidRed.png',
  isHumidGray: '/images/main/ingredient/isHumidGray.png',
  isHarmness: '/images/main/ingredient/harmness.png',
  allergicR: '/images/main/ingredient/isAllergicRed.png',
  allergicS: '/images/main/ingredient/isAllergicGray.png',
  isDSLtrue: '/images/main/ingredient/cautionCanada.png',
  isEPAY: '/images/main/ingredient/cautionUsa.png',
  echaCMRP: '/images/main/ingredient/globalIngredient.png',
  echaCMRC: '/images/main/ingredient/danger.png',
  echaCMRCC: '/images/main/ingredient/danger2.png',
  echaCMRCCC: '/images/main/ingredient/danger.png',
  isMomguide: '/images/main/ingredient/is_mc.png',
  momguideGrade1: '/images/main/ingredient/isMc.png',
  momguideGrade2: '/images/main/ingredient/is_mc_gray.png',
  momguideGrade5: '/images/main/ingredient/isMc.png',
  momguideGrade6: '/images/main/ingredient/is_mc_gray.png',
  ingredientGradeX: '/images/main/ingredient/x.png',
  ingredientGradeA: '/images/main/ingredient/a.png',
  ingredientGradeB: '/images/main/ingredient/b.png',
  ingredientGradeC: '/images/main/ingredient/c.png',
  ingredientGradeD: '/images/main/ingredient/d.png',
  ingredientGradeF: '/images/main/ingredient/f.png',
  ingredientGradeFA: '/images/main/ingredient/filter4.png',
  ingredientGradeFB: '/images/main/ingredient/filter3.png',
  ingredientGradeFC: '/images/main/ingredient/filter1.png',
  ingredientGradeFD: '/images/main/ingredient/filter2.png',

  ingredientDataC: '/images/main/ingredient/ingredientDataC.png',
  ingredientDataB: '/images/main/ingredient/ingredientDataB.png',
  ingredientDataA: '/images/main/ingredient/ingredientDataA.png',

  highcaution: '/images/main/ingredient/highcaution.png',
  lowcaution: '/images/main/ingredient/lowcaution.png',
  moderatecaution: '/images/main/ingredient/moderatecaution.png',
  nocaution: '/images/main/ingredient/nocaution.png',
  checkIconRed: '/images/main/ingredient/checkIconRed.png',
  questionIcon: '/images/main/ingredient/moveFaq.png',

  reviewBlurImage: '/images/main/reviewBlurImage.png',
  productEditRequestIcon: '/images/main/productEditRequestIcon.png',
  share: '/images/main/share.png',
  lock: '/images/main/lock.svg',

  rank1: '/images/main/rank/rank1.svg',
  rank2: '/images/main/rank/rank2.svg',
  rank3: '/images/main/rank/rank3.svg',

  cancelBtn: '/images/popup/cancel_btn.png',
  cancelBtnSvg: '/images/cancel_btn.svg',

  home: '/images/main/home.svg',
}

export const categoryColorData = [
  {
    id: 701,
    color: '#BDE466',
  },
  {
    id: 801,
    color: '#BDE466',
  },
  {
    id: 702,
    color: '#F49197',
  },
  {
    id: 803,
    color: '#BA9BEE',
  },
  {
    id: 804,
    color: '#9A781F',
  },
  {
    id: 703,
    color: '#73ABFF',
  },
  {
    id: 805,
    color: '#73ABFF',
  },
  {
    id: 6,
    color: '#FAC53E',
  },
]

export const ingredientExplainText: { [key: string]: string } = {
  isCare: 'EWG 등 공신력 있는 기관에서 지정한 주의해야 할 성분',
  isHarmness: '유독물질로 구분됨\n-국립환경과학원 고시',
  isHumidRed: 'MIT / CMIT / PHMG / PGH가 함유된 경우, 가습기 살균제 성분으로 흡입시 독성을 유발할 수 있어 주의가 필요함',
  isColorRed:
    '“색소”라고 성분을 표기한 경우, 색소 성분명이 불분명한 경우, 각종 콜타르 염료(Coal Tar Dyes)가 사용된 경우, “형광증백제” 기능을 하는 성분이 함유된 경우 주의가 필요함',
  isFragranceRed: '“향료”라고 성분을 표기한 경우, 구체적인 성분을 알 수 없어 주의가 필요함',
  isAmmoniumRed:
    '“4급 암모늄 살균보존제” 성분은 피부 건조 및 자극을 유발할 수 있으며, 흡입시 호흡기 독성을 유발할 수 있어 사용시 주의가 필요함',
  isAmmoniumYellow:
    '“4급 암모늄 정전기방지제” 성분은 민감한 피부에 자극을 유발할 수 있으며 살균보존제 성분에 비해 비교적 낮은 주의가 필요함',
  isSlsSlesRed: '“소듐라우릴설페이트(SLS), 소듐라우레스설페이트(SLES)”는 피부 자극성 계면활성제로 주의가 필요함',
  echaBreathRed: '흡입시 알레르기성 반응, 천식 또는 호흡곤란 등을 일으킬 수 있음\n-유럽화학물질청(ECHA)',
  allergicR: '적은 양으로도 사람에 따라 알레르기 반응을 일으킬 가능성이 상당히 높음\n-유럽연합위원회(EC)',
  allergicS: '적은 양으로도 사람에 따라 알레르기 반응을 일으킬 가능성이 존재함\n-유럽연합위원회(EC)',
  echaGrowthRed:
    '생식기능, 생식능력 또는 태아의 발생, 발육에 유해한 영향을 주거나 그것이 의심됨. 특정한 노출 경로 및 환경에 따라 나타나므로 해당 성분이 포함되어 있다고 해서 생식 독성을 일으키는 제품이라고 단정할 순 없음\n-유럽화학물질청(ECHA)',
  isEPAY: '유해성 논란이 있어 주의가 필요함\n-미 환경보호청 (EPA)',
  isDSLtrue: '인체에 독성이 있을 수 있음\n-캐나다 환경부(DSL)',
  echaCMRP: '화장품에서의 사용을 전적으로 혹은 특정 용도로 금지함\n-미국, 캐나다, 유럽, 국제 향료 협회 등',
  echaCMRC:
    '발암성물질/변이원성물질/생식독성물질/내분비계교란물질 중 하나로 알려져 있음\n-국제암연구소(IARC), 미국독성학프로그램(NTP) 등의 공신력 있는 기관',
  isMomguidetrue:
    '국내외 기관의 한계로 인해 주의/유해/관심성분으로 등록되지 않은 성분 중, 유해 성분으로 합성되거나 유해 가능성이 있는 성분',
  momguideGrade1: '어린아이, 민감한 피부는 피해야 할 성분, 성분 설명 참고 바람\n-미국원료검토위원회(CIR), 독성학 저널, 원료합성방법 등',
  momguideGrade2:
    '일반적으로는 사용해도 무방하나 문제가 생겼다면 체크해 볼만한 성분, 성분 설명을 참고 바람\n-미국원료검토위원회(CIR), 독성학 저널, 원료합성방법 등',
  echaSkinRed: '알레르기성 피부 반응을 일으킬 수 있음\n-유럽화학물질청(ECHA)',
  echaGrowth:
    '생식기능, 생식능력 또는 태아의 발생, 발육에 유해한 영향을 주거나 그것이 의심됨. 특정한 노출 경로 및 환경에 따라 나타나므로 해당 성분이 포함되어 있다고 해서 생식 독성을 일으키는 제품이라고 단정할 순 없음\n-유럽화학물질청(ECHA)',
}

export const ingredientGradeText = {
  ewgGradeA: '매우 낮은 위험도',
  ewgGradeB: '낮은 위험도',
  ewgGradeC: '중간 위험도',
  ewgGradeD: '높은 위험도',
  ewgGradeF: '매우 높은 위험도',
  ewgGradeX: '정보 없음',
  ewgCodeAA: '근거 데이터 충분',
  ewgCodeAB: '근거 데이터 보통',
  ewgCodeAC: '근거 데이터 부족',
  ewgCodeBA: '근거 데이터 충분',
  ewgCodeBB: '근거 데이터 보통',
  ewgCodeBC: '근거 데이터 부족',
  ewgCodeCA: '근거 데이터 충분',
  ewgCodeCB: '근거 데이터 보통',
  ewgCodeCC: '근거 데이터 부족',
  ewgCodeX: '정보 없음',
  cosGradeA: '낮은 위험도',
  cosGradeB: '중간 위험도',
  cosGradeC: '높은 위험도',
  cosGradeX: '정보 없음',
}

export const ingredientExplainTitle = {
  isEPAY: '미국 주의 성분',
  isDSLtrue: '캐나다 주의 성분',
  allergicR: '알레르기 주의 성분',
  allergicS: '알레르기 주의 성분',
  isMomguidetrue: '맘가이드 주의성분',
  momguideGrade1: '맘가이드 주의성분',
  momguideGrade2: '맘가이드 주의성분',
  echaCMRP: '해외 금지 성분',
  echaCMRC: '고독성 물질',
  echaBreathRed: '흡입 주의 성분',
  echaSkinRed: '알레르기 주의 성분',
  echaGrowthRed: '발달 · 생식 독성 성분',
  isHarmness: '국내 유해화학물질',
  isColorRed: '색소/형광증백제',
  isFragranceRed: '향료',
  isAmmoniumRed: '4급 암모늄',
  isSlsSlesRed: 'SLS/SLES',
  isHumidRed: '가습기 살균제 성분',
}

/**
 * 성분 독성 정보
 */
export const ToxicityInformationsData = [
  {
    title: '알레르기 주의 성분',
    label:
      '알레르기성 피부 반응을 일으킬 수 있음.\n해당 독성은 개개인에 따라 다르게 나타나므로 사용전 테스트를 권장드리며, 테스트 후 이상이 없다면 안전합니다.\n-유럽화학물질청(ECHA)\n-유럽연합위원회(EC)',
    icon: 'echaSkinRed',
    routeName: 'TipsDetail',
    param: {
      id: 1316,
    },
  },
  {
    title: '흡입 주의 성분',
    label:
      '흡입시 알레르기성 반응, 천식 또는 호흡곤란 등을 일으킬 수 있음.\n흡입가능성이 없는 제형의 제품이라면, 크게 우려하지 않으셔도 됩니다.\n-유럽화학물질청(ECHA)',
    icon: 'echaBreathRed',
    routeName: 'TipsDetail',
    param: {
      id: 1316,
    },
  },

  // {
  //   title: '발달 · 생식 독성 성분',
  //   label:
  //     '생식기능, 생식능력 또는 태아의 발생, 발육에 유해한 영향을 주거나 그것이 의심되는 성분입니다. 특정한 노출 경로 및 환경에 따라 나타나므로 해당 성분이 포함되어 있다고 해서 생식 독성을 일으키는 제품이라고 단정할 순 없습니다.\n-유럽화학물질청(ECHA)',
  //   icon: 'echaGrowthRed',
  //   routeName: 'TipsDetail',
  //   param: {
  //     id: 1316,
  //   },
  // },
]
export const InterestIngredientData = [
  {
    label: '“소듐라우릴설페이트(SLS), 소듐라우레스설페이트(SLES)”는 피부 자극성 계면활성제로 주의가 필요함',
    icon: 'isSlsSlesRed',
    routeName: 'TipsDetail',
    param: {
      id: 1555,
    },
  },
  {
    label:
      '“향료”라고 성분을 표기한 경우, 구체적인 성분을 알 수 없어 주의가 필요함\n덴탈케어에 함유되는 "향미료" 성분은 특정한 향을 부가하기 위해 의약품 또는 식품에 첨가하는 물질로 천연향료, 합성향료를 모두 포함하기 때문에 확인이 필요함',
    icon: 'isFragranceRed',
    routeName: 'TipsDetail',
    param: {
      id: 1560,
    },
  },
  {
    label: '“4급 암모늄 살균보존제” 성분은 피부 건조 및 자극을 유발할 수 있으며, 흡입시 호흡기 독성을 유발할 수 있어 사용시 주의가 필요함',
    icon: 'isAmmoniumRed',
    routeName: 'TipsDetail',
    param: {
      id: 1567,
    },
  },
  {
    label: '“4급 암모늄 정전기방지제” 성분은 민감한 피부에 자극을 유발할 수 있으며 살균보존제 성분에 비해 비교적 낮은 주의가 필요함',
    icon: 'isAmmoniumYellow',
    routeName: 'TipsDetail',
    param: {
      id: 1567,
    },
  },
  {
    label:
      '“색소”라고 성분을 표기한 경우, 색소 성분명이 불분명한 경우, 각종 콜타르 염료(Coal Tar Dyes)가 사용된 경우, “형광증백제” 기능을 하는 성분이 함유된 경우 주의가 필요함',
    icon: 'isColorRed',
    routeName: 'TipsDetail',
    param: {
      id: 89,
    },
  },
  {
    label: 'MIT / CMIT / PHMG / PGH가 함유된 경우, 가습기 살균제 성분으로 흡입시 독성을 유발할 수 있어 주의가 필요함',
    icon: 'isHumidRed',
    routeName: 'TipsDetail',
    param: {
      id: 91,
    },
  },
]

// @chky, 2022-05-05 11:52:04
// 매거진 및 이벤트 상세 데이터 연동

// @chky, 2022-10-19 14:23:59
// 매거진 및 제품검색 server api url
const envStage = process.env.GATSBY_STAGE
export const apiUrl =
  envStage == 'local' ? 'http://localhost:3000' : envStage == 'dev' ? 'https://dev.momguide.co.kr' : 'https://api.momguide.co.kr'
