exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ewg-grade-index-tsx": () => import("./../../../src/pages/ewgGrade/index.tsx" /* webpackChunkName: "component---src-pages-ewg-grade-index-tsx" */),
  "component---src-pages-image-modal-index-tsx": () => import("./../../../src/pages/ImageModal/index.tsx" /* webpackChunkName: "component---src-pages-image-modal-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ingredient-detail-[id]-tsx": () => import("./../../../src/pages/ingredientDetail/[id].tsx" /* webpackChunkName: "component---src-pages-ingredient-detail-[id]-tsx" */),
  "component---src-pages-ingredient-detail-index-tsx": () => import("./../../../src/pages/ingredientDetail/index.tsx" /* webpackChunkName: "component---src-pages-ingredient-detail-index-tsx" */),
  "component---src-pages-interest-ingredient-index-tsx": () => import("./../../../src/pages/interestIngredient/index.tsx" /* webpackChunkName: "component---src-pages-interest-ingredient-index-tsx" */),
  "component---src-pages-mom-standard-index-tsx": () => import("./../../../src/pages/momStandard/index.tsx" /* webpackChunkName: "component---src-pages-mom-standard-index-tsx" */),
  "component---src-pages-policies-event-index-tsx": () => import("./../../../src/pages/policies/event/index.tsx" /* webpackChunkName: "component---src-pages-policies-event-index-tsx" */),
  "component---src-pages-policies-privacy-index-tsx": () => import("./../../../src/pages/policies/privacy/index.tsx" /* webpackChunkName: "component---src-pages-policies-privacy-index-tsx" */),
  "component---src-pages-policies-service-index-tsx": () => import("./../../../src/pages/policies/service/index.tsx" /* webpackChunkName: "component---src-pages-policies-service-index-tsx" */),
  "component---src-pages-policies-store-index-tsx": () => import("./../../../src/pages/policies/store/index.tsx" /* webpackChunkName: "component---src-pages-policies-store-index-tsx" */),
  "component---src-pages-product-detail-[id]-tsx": () => import("./../../../src/pages/productDetail/[id].tsx" /* webpackChunkName: "component---src-pages-product-detail-[id]-tsx" */),
  "component---src-pages-product-detail-index-tsx": () => import("./../../../src/pages/productDetail/index.tsx" /* webpackChunkName: "component---src-pages-product-detail-index-tsx" */),
  "component---src-pages-product-grade-explain-index-tsx": () => import("./../../../src/pages/productGradeExplain/index.tsx" /* webpackChunkName: "component---src-pages-product-grade-explain-index-tsx" */),
  "component---src-pages-product-result-[id]-tsx": () => import("./../../../src/pages/productResult/[id].tsx" /* webpackChunkName: "component---src-pages-product-result-[id]-tsx" */),
  "component---src-pages-product-result-index-tsx": () => import("./../../../src/pages/productResult/index.tsx" /* webpackChunkName: "component---src-pages-product-result-index-tsx" */),
  "component---src-pages-search-index-tsx": () => import("./../../../src/pages/search/index.tsx" /* webpackChunkName: "component---src-pages-search-index-tsx" */),
  "component---src-templates-event-list-tsx": () => import("./../../../src/templates/eventList.tsx" /* webpackChunkName: "component---src-templates-event-list-tsx" */),
  "component---src-templates-magazine-list-tsx": () => import("./../../../src/templates/magazineList.tsx" /* webpackChunkName: "component---src-templates-magazine-list-tsx" */),
  "component---src-templates-magazine-tsx": () => import("./../../../src/templates/magazine.tsx" /* webpackChunkName: "component---src-templates-magazine-tsx" */)
}

